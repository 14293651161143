.LoginAction {
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  border-radius: 0px 0px 10px 10px;
  margin: 0 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  color: white;
}

.LoginActionLoading {
  background-color: #444444;
  color: #ffffff;
}

.LoginActionReady {
  cursor: pointer;
  font-weight: 500;
  background-color: #303030;
  color: #f1f1f1;
}

.LoginActionReady:hover {
  background-color: #242424;
}
