.FieldsContainer {
  scroll-margin-top: 60px;
}

.InputFields {
  display: flex;
  flex-direction: column;
}

.InputFields > input {
  margin-bottom: 3px;
  border-radius: 0;
}

.InputFields > input:first-child {
  border-radius: 10px 10px 0px 0px;
}

.Error {
  color: #ff5678;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
}
