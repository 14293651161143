.LoginInput {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 100%;
  box-sizing: border-box;
}

.LoginField {
  height: 50px;
  line-height: 50px;
  padding: 0px 20px;
  font-size: 16px;
  color: #242424;
  background-color: #f1f1f1;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.LoginFieldError {
  background-image: url(/static/error_icon.png);
  background-repeat: no-repeat;
  background-position: right 15px top 15px;
  background-size: 18px;
}

.LoginFieldLoading {
  pointer-events: none;
  color: #cecece;
}

.LoginStraightTopLeft {
  border-radius: 0px 15px 0px 0px;
}

@media screen and (min-width: 600px) {
  .LoginInput {
    max-width: 340px;
  }
}

@media screen and (min-width: 960px) {
  .LoginInput {
    width: 340px;
  }
}
