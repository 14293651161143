.RiveTabList {
  display: flex;
  align-items: stretch;
  border-radius: 8px;
  background-color: #0b0b0b;
  height: 36px;
  padding: 2px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 100%;
  flex: 0 1 0px;
}

.RiveTabList::-webkit-scrollbar {
  display: none;
}

.FitContent {
  max-width: fit-content;
}

.RiveTab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 1px 8px;
  min-width: 80px;
  width: 100%;
  cursor: pointer;
  color: #aaaaaa;
  font-weight: normal;
  text-decoration: none;
}

.SelectedRiveTab {
  cursor: default;
  background-color: #323232;
  border-radius: 6px;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 800px) {
  .RiveTabList {
    overflow: visible;
  }

  .SelectedRiveTab {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }
}
