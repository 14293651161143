.SubAction {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  margin: 0px 15px 15px 15px;
  text-decoration: none;
}
