.Container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.Animation {
  top: 0;
  height: 240px;
  flex: 1 1 auto;
  position: -webkit-sticky;
  position: sticky;
  background-color: #3c2b4b;
}

.LoginInputContainer {
  flex: 0 0 auto;
  background-color: white;
  overflow: hidden;
  z-index: 1;
}

.LoginInput {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 340px;
  padding: 0 20px;
  margin: 40px auto;
}

.RiveLogo {
  height: 30px;
  margin-bottom: 20px;
}

.RiveLogo img {
  height: 100%;
}

@media screen and (min-width: 960px) {
  .Container {
    flex-direction: row;
  }

  .Animation {
    width: 50%;
    height: 100vh;
    flex: 3 1 auto;
  }

  .LoginInputContainer {
    flex: 2 0 auto;
    width: 380px;
    display: flex;
    padding: 100px;
    flex-direction: column;
    justify-content: center;
  }

  .LoginInput {
    margin: 0;
  }

  .RiveLogo {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 600px) {
  .LoginInput {
    padding-top: 60px;
  }

  .RiveLogo {
    height: 40px;
  }
}
