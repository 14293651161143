.RiveDropDown {
  background-color: #0b0b0b;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.4);
}

.RiveDropDownLarge {
  padding: 12px 8px;
}

.RiveDropDown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
