.Checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: var(--color-field);
  cursor: pointer;
}
