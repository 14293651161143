.Avatar {
  height: 36px;
  width: 36px;
}

.Username {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 700;
}

.NavProfileBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  animation: fade 0.5s ease-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
