.NavDropDown {
  margin-left: 20px;
  cursor: pointer;
}

.NavDropDown h1 {
  margin: 15px 0;
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: -0.04em;
  text-wrap: nowrap;
}

.NavDropDown h1 a {
  color: inherit;
  text-decoration: inherit;
}

.MenuTitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.NavDropDown svg {
  transform: translateY(1px);
  transition: transform 100ms ease;
}

.NavDropDown:hover svg {
  transform: translateY(3px);
}

.Menu {
  position: absolute;
  top: 68px;
  left: 8px;
  right: 8px;
}

@media screen and (min-width: 800px) {
  .NavDropDown {
    position: relative;
  }

  .Menu {
    top: 56px;
    left: 0px;
    width: 140px;
  }
}
