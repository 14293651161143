.PasswordInputContainer {
  position: relative;
  margin-bottom: 3px;
}

.ShowPasswordButton {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #888888;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.ShowPasswordButton:hover {
  color: #242424;
}

.PasswordInputContainer:first-child > input {
  border-radius: 10px 10px 0px 0px;
}
