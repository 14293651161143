
ul.PasswordErrors {
  list-style: none;
  margin-top: 0px;
  margin-bottom: 16px;
  padding: 0;
  padding-inline-start: 16px;
}

.PasswordErrors > li {
  margin: 0;
  padding: 0;
  list-style-type: disc;
}

.PasswordMust {
  color: #242424;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
}


.PasswordMustDark {
  color: #888888;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
}

.PasswordError {
  color: #ff5678;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
}