.Container {
  display: flex;
  padding: 0 4px;
  gap: 8px;
  align-items: center;
  letter-spacing: -0.02em;
}

.Avatar {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
}

.Details {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
}

.Username {
  font-size: 14px;
  color: #fff;
  line-height: 17px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.Email {
  font-size: 12px;
  color: #666;
  line-height: 15px;
  letter-spacing: -0.02em;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
