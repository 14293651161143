.Legal {
  bottom: 0px;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #888888;
}

.Legal a {
  color: #242424;
  font-weight: 500;
  text-decoration: none;
}

.EmailOptIn {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.EmailOptIn p {
  margin: 0 0 0 8px;
  font-size: 14px;
  color: #888888;
}
