.Container {
  z-index: 10;
  position: fixed;
  /* width: 100%; */
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
}

.NavMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 12px 16px;
  margin: 0 auto;
  gap: 0px;
  background-color: #1b1b1b;
}

.NavMenuShadow {
  height: 12px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0)
  );
}

.NavMenuShadowHidden {
  visibility: hidden;
}

.NavIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  order: 1;
}

.NavUserSection {
  order: 2;
}

.NavProfileBanner {
  order: 2;
}

.CenterNav {
  width: 100%;
  display: flex;
  gap: 8px;
  order: 3;
  flex-direction: column;
}

.NavCommunitySearch {
  flex-grow: 1;
}

.MenuPadding {
  height: 114px;
}

.MenuPaddingFilters {
  height: 120px;
}

@media screen and (min-width: 768px) {
  .CenterNav {
    flex-direction: row;
    gap: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .NavMenu {
    padding: 12px 32px;
    margin: 0 auto;
    gap: 16px;
    flex-wrap: nowrap;
  }

  .MenuPadding {
    height: 78px;
  }

  .CenterNav {
    order: 2;
  }

  .NavProfileBanner {
    order: 2;
  }

  .NavUserSection {
    order: 3;
  }

  .MenuPaddingFilters {
    height: 72px;
  }
}
