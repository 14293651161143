.Container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1b1b1b;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.02em;
  color: #aaa;
}

.NoPadding {
  padding: 0;
}
