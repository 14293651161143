.NavUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.Avatar {
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
}

.Menu {
  position: absolute;
  margin: 0 auto;
  top: 64px;
  left: 8px;
  right: 8px;
}

@media screen and (min-width: 800px) {
  .Menu {
    position: relative;
    width: 240px;
    left: -200px;
    top: 10px;
  }
}

.NewNotification::before {
  content: '·';
  color: #57a5e0;
  font-size: 32px;
  margin-left: 4px;
}

.NotificationContainer {
  display: flex;
  align-items: center;
  height: 100%;
}
