.SearchField {
  height: 36px;
  border: none;
  font-size: 14px;
  padding: 0px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #0b0b0b;
  font-weight: normal;
}

.SearchField::placeholder {
  font-size: 14px;
  color: #666666;
}
