.RivePillButton {
  height: 32px;
  border-radius: 16px;
  font-weight: bold;
  letter-spacing: -0.02rem;
  font-size: 14px;
  background-color: var(--color-blue);
  padding: 5px 24px 6px 24px;
  color: #ffffff;
  cursor: pointer;
  min-width: fit-content;
  white-space: nowrap;
}

.RivePillButton:hover {
  background-color: #57a5e0;
}
