.RiveDropDownItem {
  height: 16px;
  padding: 8px 12px;
  color: #888;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02rem;
  font-weight: 500;
  border-radius: 4px;
  margin: 0;
}

.RiveDropDownItemLarge {
  padding: 12px 12px;
  height: 16px;
}

.RiveDropDownItem:hover {
  background-color: #242424;
  color: #fff;
  cursor: pointer;
}

.RiveDropDownItem:hover a {
  color: #fff;
}

.Highlight {
  color: #fff;
}
