.LoginTransition-exit {
  opacity: 1;
  transform: translateX(0px);
}

.LoginTransition-exit-active {
  opacity: 0;
  transform: translateX(-200px);
  transition: all 500ms cubic-bezier(0.5, 0, 1, 1);
}

.LoginInput {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 340px;
  padding: 0 20px;
  margin: 40px auto;
}

.RiveLogo {
  height: 30px;
  margin-bottom: 20px;
}

.RiveLogo img {
  height: 100%;
}

.LoginCaption {
  color: #888888;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
}

.SocialLogin {
  height: 50px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  line-height: 50px;
  font-size: 16px;
  color: #888888;
}

.SocialLogin div {
  flex: 50%;
  text-indent: 20px;
  background-color: #f1f1f1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.SocialLogin > div:first-child {
  margin-right: 3px;
}

.SocialLogin img {
  height: 20px;
  margin: 15px;
}

.SocialLogin div:hover {
  color: #242424;
  font-weight: 500;
  background-color: #d8d8d8;
}

.LoginDivider {
  height: 3px;
  border-radius: 1.5px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}

.LoginSignupTabs {
  display: flex;
  height: 50px;
  margin-top: 10px;
}

.LoginSignupTabs > button {
  margin: 0;
}

.Tab {
  cursor: pointer;
  font-size: 16px;
  color: #888888;
  line-height: 50px;
  background-color: #f1f1f1;
  border-radius: 10px 10px 0px 0px;
}

.Tab div {
  padding: 0px 20px;
}

button.Selected {
  color: #242424;
  font-weight: 500;
  background-color: #f1f1f1;
}

.Unselected div {
  background-color: white;
}

button.Login.Unselected div {
  border-radius: 0px 0px 10px 0px;
}

button.Signup.Unselected div {
  border-radius: 0px 0px 0px 10px;
}

.Filler {
  flex-grow: 1;
  background-color: #f1f1f1;
}

.Filler div {
  width: 100%;
  height: 100%;
  background-color: white;
}

.FlareBanner {
  height: 50px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
  font-size: 16px;
  color: #444444;
  border: 2px solid #ffc452;
  background-color: #ffc45220;
  background-image: url(/static/warning.png);
  background-repeat: no-repeat;
  background-position: left 11px top 11px;
  cursor: pointer;
}

.FlareBanner:hover {
  background-color: #ffc45240;
}

.FlareBanner > p {
  padding-top: 1px;
  margin-left: 45px;
}

@media screen and (min-width: 600px) {
  .LoginInput {
    padding-top: 60px;
  }

  .RiveLogo {
    height: 40px;
  }
}

@media screen and (min-width: 960px) {
  .LoginInput {
    margin: 0;
  }

  .RiveLogo {
    margin-bottom: 60px;
  }
}
