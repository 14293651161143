.Avatar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-right: 15px;
  border-radius: 50%;
  border: 2px solid #1d1d1d;
  background-color: #303030;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.AvatarInitial {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #f1f1f1;
  padding-left: 2px;
}
