.LoginLinkHighlight {
  color: #303030;
}

.LoginLinkText {
  color: #888888;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
}

.LoginLink {
  background-color: #303030 !important;
  color: #f1f1f1;
}

.LoginLinkDisabled {
  background-color: #cecece !important;
  pointer-events: none;
}

.LoginLink:hover {
  color: #f1f1f1 !important;
}

.LoginLinkCancelDisabled {
  color: #cecece !important;
  pointer-events: none;
}

.SocialLogin {
  height: 50px;
  display: flex;
  gap: 3px;
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  line-height: 50px;
  font-size: 16px;
  color: #888888;
}

.SocialLogin div {
  flex: 50%;
  text-indent: 20px;
  background-color: #f1f1f1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.SocialLogin img {
  height: 20px;
  margin: 15px;
}

.SocialLogin div:hover {
  color: #242424;
  font-weight: 500;
  background-color: #d8d8d8;
}
